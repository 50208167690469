/** @jsx jsx */
import React from 'react'
import {Box,Button,jsx,Heading,Grid,Text,Link as ThLink} from 'theme-ui'
//import {useSpring,animated,config} from 'react-spring'
import Layout from '../../components/layout'
import MySEO from '../../components/seo'
import {Link} from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import {graphql} from 'gatsby'


const Marko = () => {
  return(
  <Box sx={{height:400,marginTop:'20px'}}>
<iframe width="100%"  height="315" src="https://www.youtube.com/embed/mjKRgnnpVlo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</Box>
  )
}



export default function FuenfterSalon({data}){
const [yout,setYout] =React.useState(false)
return(
  <Layout>
      <MySEO
     title="Der fünfte Salon von K.0.M in St. Goar"
     
    />
    <Box sx={{maxWidth:1100,paddingBottom:40}}>
    <Heading as="h1" sx={{
                     fontWeight:500,
                     fontSize:[2,3],
                     mt:4
                 }}>Salon N° 5 
          </Heading>
          <span style={{fontSize:20,fontWeight:600, paddingBottom:20,display:"block"}}>am Sonntag, 2. Oktober, ab 18:00 Uhr<br/>
Ort: Funboat Marina im Stadthafen St. Goar
          </span>
         
<Grid columns={[1,1,2]} gap={3}>
   
  
  </Grid>


  <Grid mt="20px" columns={[1,2,2]} gap={5} >
  <Box>
  <Text sx={{fontSize:[2,2,3],lineHeight:1.3}}>
   Jazz mit dem Mebus Weinig Duo: <br/>Marko Mebus, Trompete  <br/>Bastian Weinig, Kontrabass 
   </Text>
  <Text sx={{fontSize:[2,2,3],lineHeight:1.3}}>
   Mareike Rabea Knevels (ehemalige Burgenbloggerin) liest aus ihrem Buch „Zwischen Riesling, Tahini und Pixeln“
     </Text>
     <Box mb="20px" py="2">
  <a href="/pdf/Flyer_Salon5.pdf">Download Flyer</a> 
  </Box>
    
      <Text sx={{fontSize:3}}>Das Mebus Weinig Duo</Text>
    <Text>
Das <strong>Mebus Weinig Duo</strong> zelebriert sowohl die solistische Qualität der beiden Musiker, als auch die außergewöhnliche Verbindung von Trompete und Kontrabass – eine klangliche Symbiose des Frequenzspektrums. Wie zwei unterschiedliche Pole stehen die hohen, verspielten Trompetentöne im Kontrast zum erdigen, tiefen Kontrabasssound und verschmelzen doch miteinander zu einer Einheit. Gegensätze ziehen sich an, das gilt auch für die beiden Musikerpersönlichkeiten, die eine lange Freundschaft eint.

</Text>
<Text>
Zu ihrem Debüt Album <i>Dualität</i> haben beide Musiker jeweils vier Kompositionen beigesteuert, die belegen wie vielseitig und abwechslungsreich ein Duo sein kann. Mit Spoof the Greenhorn und Ol’Sport – dem „Alten Kumpel Jazz“ gewidmet – verneigen sich die beiden Musiker vor der Jazztradition. Im Gegensatz dazu erforschen Morgengrauen und Sonnenuntergang und The Home eher zeitgenössischere Gefilde. Eigene Erfahrungen, Erlebnisse und Gefühle (Schmelzende Eisblumen, What She Might
Be Thinking) werden genauso musikalisch ausgedeutet, wie (ir-)rationale, mathematische Zahlen (100 Freunde
der Zahl Pi). Diese inhaltlichen Dualitäten sprechen für die Vielseitigkeit der beiden Musiker, die sich zum Schluss ihres Programms mit Cole Porters What Is This Thing Called Love bei einem ihrer Lieblingsstandards wieder treffen.<br/> <strong>Marko Mebus</strong> - Trompete und <strong>Bastian Weinig</strong> - Kontrabass
</Text>
<a href="https://www.mebusweinigduo.de/">www.mebusweinigduo.de/</a>


 
{yout?
    <Marko />
:
<Box>
<Button sx={{cursor:"pointer", bg:"blue", margin:'5px 0px'}} onClick={()=>setYout(!yout)}><Text m="0" p="0" color="white">&rarr; Das Duo auf Youtube anhören. <span style={{color:'lawngreen',fontSize:'0.9rem',lineHeight:"1.2"}}>(Wir machen darauf aufmerksam, dass beim Start von YouTube die Datenschutzbestimmungen von YouTube gelten und nicht die unserer Webseite.)</span></Text></Button>
</Box>


}
<Text sx={{fontSize:3,mt:yout?"10px":"25px"}}>Das Buch zur Lesung</Text>
<Text>
Die ehemalige Burgenbloggerin <strong>Mareike Rabea Knevels </strong>hat 2019 ein Jahr lang erkundet, warum sich Menschen im Welterbe Oberes Mittelrheintal zu Hause fühlen. Jetzt hat die junge Frau ein Buch darüber geschrieben.
Das Buch mit dem Titel <i>Zwischen Riesling, Tahini und Pixeln</i> ist eine einfühlsame Annäherung an einen Sehnsuchtsort und seine Menschen. Es wird begleitet von liebevollen und prägnanten Zeichnungen.
Zuhause fühlte sich Mareike Rabea Knevels an vielen Wohnorten — aber Heimat? Das war für sie kein Thema. Bis sie für ein Blogprojekt auf eine Burg ins Tal der Loreley zog. In einer der bekanntesten Landschaften Deutsch- lands lernte sie viele unterschiedliche Menschen und ihre Geschichten kennen. Alteingesessene, Weggezogene, Zurückgekommene, Zugezogene und Geflüchtete erzählen ihr, was Heimat für sie bedeutet. Jeder hat einen anderen Blick auf die Region, aber das Leben am Welterbe Oberer Mittelrhein verbindet sie alle.</Text>
<Text>
Mareike Rabea Knevels, Jahrgang 1988, wurde in Hamm geboren und hat im Ruhrgebiet, in einem Dorf im Hunsrück, in Weimar und in Barcelona gelebt. Mareike studierte Kommunikationsdesign und arbeitet als Dozentin, Illustratorin und Autorin.
<a href="https://www.mareike-knevels.de"><br/>
www.mareike-knevels.de</a><br/>
<br/>
     
</Text>          <Text sx={{fontSize:3}}>Der Gastgeber</Text>
        <Text sx={{py:3}}>
        Der gebürtige Kölner <strong>Richard Vogel</strong> ist seit 2014 Wahl-Mittelrheintaler und konnte seine Träume als Boots-Fan und gelernter Hotelfachmann in St. Goar als Hafenmeister verwirklichen. Auf der Funboat Marina verköstigt er seine vor Anker liegenden Gäste und ist mit weiteren Hausbooten auch Gastgeber für bootlose Reisende. Seine Marina im Stadthafen St. Goar und die Hausboote sind durch ihre Originalität in jedem Fall einen Besuch Wert!
        </Text>
     
   
  
  <Text sx={{fontSize:3}}>Der Veranstaltungsort:</Text>
  <Text>
   Funboat Marina im Stadthafen <br/>
   Am Hafen 14, 56329 St. Goar<br/>
  Beginn 18:00 Uhr
  </Text>
  <Text color="#FF5F1F">Aufgrund der herbstlichen Temperaturen empfehlen wir die Mitnahme warmer Kleidung.</Text>



      
        </Box>
  <Box>
  
  <GatsbyImage image={data.mar.childImageSharp.gatsbyImageData} alt="Der Gastgeber"/> 
  <Text sx={{fontSize:0}}>Der Veranstaltungsort: Die Funboat Marina im Stadthafen St. Goar</Text>
<GatsbyImage image={data.moe.childImageSharp.gatsbyImageData} alt="Marko Mebus"/> 
<Text sx={{fontSize:0}}  mt="6px">Marko Mebus | ©Peter Tümmers</Text>


 <GatsbyImage image={data.wei.childImageSharp.gatsbyImageData} alt="Bastian Weinig"/> 

 <Text sx={{fontSize:0}}> Bastian Weinig | ©Luisa Melzig </Text>

 

 <GatsbyImage image={data.kne.childImageSharp.gatsbyImageData} alt="Mareike Knebels"/> 
<Text sx={{fontSize:0}}>Mareike Rabea Knevels | ©Andreas Jöckel</Text>

<GatsbyImage image={data.gast.childImageSharp.gatsbyImageData} alt="Der Gastgeber"/> 
  <Text sx={{fontSize:0}}>Der Gastgeber Richard Vogel</Text>
 

</Box>
</Grid>



<Box mt="40px">
<Text>
Wir freuen uns über Ihre A N M E L D U N G unter:<br/>
kontakt@kulturnetz-oberes-mittelrheintal.org<br/>
Das Rahmenprogramm startet um 18:00 Uhr und wird gegen 20:30 Uhr ausklingen. <br/>Die letzte Fähre ab St. Goar fährt um 21:05 Uhr.
</Text>
</Box>
<p sx={{fontSize:3,py:3}}>Dank an die Förderer</p>
<p sx={{fontWeight:600}}>

Dieser K.O.M.-Salon N°5 wird gefördert durch die „Ehrenamtlichen Bürgerprojekte“ der LAG Welterbe Oberes Mittelrheintal.
</p>
<GatsbyImage image={data.logo.childImageSharp.gatsbyImageData} alt="Förderer"/> <br/>
<Link to="/salons"><ThLink>Salons</ThLink></Link>
</Box>
    </Layout>
)
}

export const WEGquery = graphql`
  query {
    wei:file(relativePath: { eq: "salons/salon5/bastian_weinig.jpg"}) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
         
         
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
    }
    moe:file(relativePath: { eq: "salons/salon5/marko-mebus.jpeg"}) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          gatsbyImageData(
          
            layout: CONSTRAINED
            placeholder: BLURRED
          
          )
        }
      }
      kne:file(relativePath: { eq: "salons/salon5/knevels.jpg"}) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          gatsbyImageData(
           
            layout: CONSTRAINED
            placeholder: BLURRED
          
          )
        }
      }
      mar:file(relativePath: { eq: "salons/salon5/marina.jpg"}) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          gatsbyImageData(
            
            layout: CONSTRAINED
            placeholder: BLURRED
          
          )
        }
      }
      logo:file(relativePath: { eq: "salons/salon5/logos.jpg"}) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          gatsbyImageData(
           
            layout: CONSTRAINED
            placeholder: BLURRED
          
          )
        }
      }
      gast:file(relativePath: { eq: "salons/salon5/gastgeber.jpg"}) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          gatsbyImageData(
            
            layout: CONSTRAINED
            placeholder: BLURRED
          
          )
        }
      }
    
}`
